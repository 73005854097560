/* About.css */

/* Reset some default styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  color: #555555;
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Hero Section */
.about__hero {
  position: relative;
  height: 60vh;
  /* background: url('/images/hero-bg.jpg') center/cover no-repeat; */
  display: flex;
  background: linear-gradient(to bottom, #6c69fa 0%, #5046c4 100%) !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

/* .about__hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
} */

.about__hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  animation: fadeIn 1s ease-out;
}

.about__hero-title {
  font-family: "Poppins", sans-serif;
  font-size: 3em;
  margin-bottom: 20px;
}

.about__hero-subtitle {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.about__hero-button {
  padding: 12px 30px;
  font-size: 1em;
  border: none;
  border-radius: 25px;
  background-color: #5046c4;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
/* 
.about__hero-button:hover {
  background-color: #219150;
} */

/* Container for Sections */
.about__container {
  width: 90%;
  max-width: 1200px;
  margin: 60px auto;
}

/* Our Story Section */
.about__story {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  animation: fadeIn 1s ease-out;
}

.about__story-image {
  width: 37%;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.about__story-content {
  width: 50%;
  padding: 0 20px;
}

.about__story-title {
  font-size: 34px !important;
  color: #2c3e50;
  margin-bottom: 20px;
}

.about__story-text {
  font-size: 1.1em;
  color: #555555;
  line-height: 1.8;
}

/* Responsive for Our Story Section */
@media (max-width: 768px) {
  .about__story {
    flex-direction: column;
  }

  .about__story-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .about__story-content {
    width: 100%;
  }
}

/* What We Offer Section */
.about__offer {
  margin-bottom: 60px;
  animation: fadeIn 1s ease-out;
}

.about__offer-title {
  font-size: 2.5em !important;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 40px;
}

.about__offer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.about__offer-card {
  background: linear-gradient(to bottom, #6c69fa 0%, #5046c4 100%) !important;
  padding: 30px 20px;
  border-radius: 12px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about__offer-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
}

.about__offer-icon {
  font-size: 2.5em;
  color: #fff !important;
  margin-bottom: 20px;
}

.about__offer-name {
  font-family: "Poppins", sans-serif;
  font-size: 1.5em;
  color: #2c3e50;
  margin-bottom: 15px;
}

.about__offer-description {
  font-size: 1em;
  color: #555555;
  line-height: 1.6;
}

/* Team Section */
.about__team {
  margin-bottom: 60px;
  animation: fadeIn 1s ease-out;
}

.about__team-title {
  font-family: "Poppins", sans-serif;
  font-size: 2.5em;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 40px;
}

.about__team-slider {
  position: relative;
  overflow: hidden;
}

.about__team-cards {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.about__team-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  min-width: 300px;
  margin: 0 15px;
}

.about__team-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.about__team-name {
  font-family: "Poppins", sans-serif;
  font-size: 1.3em;
  color: #2c3e50;
  margin-bottom: 5px;
}

.about__team-role {
  font-size: 1em;
  color: #777777;
  margin-bottom: 10px;
}

.about__team-bio {
  font-size: 0.95em;
  color: #555555;
  line-height: 1.6;
}

/* Carousel Navigation Buttons */
.about__slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(39, 174, 96, 0.7);
  border: none;
  color: #fff;
  font-size: 2em;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
}

.about__slider-button:hover {
  background-color: rgba(39, 174, 96, 1);
}

.about__slider-button--prev {
  left: 10px;
}

.about__slider-button--next {
  right: 10px;
}

/* Contact Information Section */
.about__contact {
  background-color: #5046c4;
  color: #fff;
  padding: 40px 0;
  border-radius: 12px;
  animation: fadeIn 1s ease-out;
}

.about__contact-title {
  font-size: 2.5em !important;
  text-align: center;
  margin-bottom: 40px;
}

.about__contact-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.about__contact-card {
  display: flex;
  align-items: center;
  width: 280px;
}

.about__contact-icon {
  font-size: 1.8em;
  margin-right: 15px;
}

.about__contact-info-title {
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.about__contact-info-detail {
  font-size: 1em;
}

/* Footer Section */
.about__footer {
  background-color: #2c3e50;
  color: #bdc3c7;
  text-align: center;
  padding: 20px 0;
}

.about__footer-text {
  font-size: 0.9em;
}

.about_team {
  margin: 30px 0px;
}

.team-card {
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  min-height: 600px;
}

.team-card-header {
  position: relative;
  width: 100%;
  height: 400px;
}

.team-card img {
  width: 100%;
  height: 100%;
  /* height: 300px; */
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.team-card h6 {
  font-family: "Poppins", sans-serif;
  font-size: 1.3em;
  color: white;
  padding: 10px;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: fit-content;
  border-radius: 5px;
  background-color: var(--main-color);
  position: absolute;
  /* transform: translateY(-30px); */
}

.team-card p {
  font-size: 1em;
  color: #555555;
  line-height: 1.6;
  padding: 25px 20px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  background-color: var(--main-color) !important;
}
