.books_page {
  /* margin-top: 80px; */
  padding: 20px;
}

.books {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  row-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  margin-top: 30px;
}

.books_page h4 {
  text-align: center;
  color: var(---second-color);
}

.books .book {
  /* margin: auto; */
  width: 300px;
  height: 180px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.3s all ease-in-out;
}

.books .book img {
  width: 100px;
  object-fit: contain;
}

.books .book h4 {
  font-size: 20px;
  color: var(--main-color);
}

.books .book:hover {
  background-color: var(--main-color);
}

.books .book:hover h4 {
  color: white;
}

@media (max-width: 767px) {
  .books_page {
    margin-bottom: 40px;
  }

  .books .book {
    width: 48%;
  }
}

@media (max-width: 565px) {
  .books .book {
    width: 47%;
  }
}

.Toastify {
  z-index: 12313423432 !important;
}

.absolutedDiv {
  position: absolute !important;
  width: 100% !important;
  height: 47px !important;
  background: white !important;
  top: 39px;
  top: 34px !important;
}
